export const state = () => ({
	id: null,
	editorReady: false,
	editorUpdating: false,
	iframeAvailable: false,
	iframeBusy: false,
	about: null,
	active: false,
	data: null,
	modules: null,
	pages: null,
	currentPage: null,
	socials: null,
	subscribe: null,
	theme: null,
	lastUpdated: null,
});

export const actions = {
	setProfileId({ commit }, str) {
		commit('id', str);
	},
	setReadyState({ commit }, bool) {
		commit('editorReady', bool);
	},
	setIframeReadyState({ commit }, bool) {
		commit('iframeBusy', bool);
	},
	editorUpdating({ commit }, bool) {
		commit('editorUpdating', bool);
	},
	setIframAvailability({ commit }, bool) {
		commit('iframeAvailable', bool);
	},
	setCurrentPage({ commit }, str) {
		commit('currentPage', str);
	},
	updateData({ commit }, payload) {
		reloadProfile(commit, payload);
	},
	reloadData({ commit }, payload) {
		reloadProfile(commit, payload);
	},
	resetData({ commit }) {
		commit('id', null);
		commit('editorReady', false);
		commit('iframeAvailable', false);
		commit('about', null);
		commit('active', false);
		commit('data', null);
		commit('modules', null);
		commit('pages', null);
		commit('currentPage', null);
		commit('socials', null);
		commit('subscribe', null);
		commit('theme', null);
		commit('lastUpdated', Date.now());
	},
};

const reloadProfile = (
	commit,
	{ id, about, active, data, modules, pages, socials, subscribe, theme },
) => {
	if (id) commit('id', id);
	if (about) commit('about', about);
	if (active) commit('active', active);
	if (data) commit('data', data);
	if (modules) commit('modules', modules);
	if (pages) commit('pages', pages);
	if (socials) commit('socials', socials);
	if (subscribe) commit('subscribe', subscribe);
	if (theme) commit('theme', theme);

	commit('lastUpdated', Date.now());
};

export const getters = {
	id: (state) => state.id,
	editorReady: (state) => state.editorReady,
	editorUpdating: (state) => state.editorUpdating,
	iframeAvailable: (state) => state.iframeAvailable,
	iframeBusy: (state) => state.iframeBusy,
	about: (state) => state.about,
	active: (state) => state.active,
	data: (state) => state.data,
	modules: (state) => state.modules,
	pages: (state) => state.pages,
	currentPage: (state) => state.currentPage,
	socials: (state) => state.socials,
	subscribe: (state) => state.subscribe,
	theme: (state) => state.theme,
	lastUpdated: (state) => state.lastUpdated,
	currentState: (state) => {
		return {
			about: state.about,
			active: state.active,
			data: state.data,
			modules: state.modules,
			pages: state.pages,
			socials: state.socials,
			subscribe: state.subscribe,
			theme: state.theme,
		};
	},
};

export const mutations = {
	id(state, str) {
		state.id = str;
	},
	editorReady(state, bool) {
		state.editorReady = bool;
	},
	editorUpdating(state, bool) {
		state.editorUpdating = bool;
	},
	iframeAvailable(state, bool) {
		state.iframeAvailable = bool;
	},
	iframeBusy(state, bool) {
		state.iframeBusy = bool;
	},
	about(state, object) {
		state.about = object;
	},
	active(state, bool) {
		state.active = bool;
	},
	data(state, array) {
		state.data = array;
	},
	modules(state, object) {
		state.modules = object;
	},
	pages(state, array) {
		state.pages = array;
	},
	currentPage(state, str) {
		state.currentPage = str;
	},
	socials(state, array) {
		state.socials = array;
	},
	subscribe(state, object) {
		state.subscribe = object;
	},
	theme(state, object) {
		state.theme = object;
	},
	lastUpdated(state, number) {
		state.lastUpdated = number;
	},
};
