//
//
//
//
//
//

export default {
	name: 'ChromeMenuPanel',
};
