//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
	siMailchimp,
	siGooglesheets,
	siApplemusic,
	siAudiomack,
	siDeezer,
	siIheartradio,
	siTunein,
	siSpotify,
	siStitcher,
	siSoundcloud,
	siYoutube,
	siYoutubemusic,
	siGenius,
	siItunes,
	siAudible,
	siGoogle,
	siOvercast,
	siBreaker,
	siPocketcasts,
	siRadiopublic,
	siApple,
	siLinkedin,
	siTwitter,
	siInstagram,
	siFacebook,
	siTiktok,
	siMedium,
	siWhatsapp,
	siPatreon,
	siPinterest,
	siGoodreads,
	siSnapchat,
	siTumblr,
} from 'simple-icons/icons';

export default {
	name: 'Icon',
	props: {
		icon: {
			type: String,
		},
		brand: {
			type: String,
		},
		size: {
			type: String,
			default: 'sm',
			validator: (v) => {
				return ['xs', 'sm', 'md', 'lg', 'xl'].includes(v);
			},
		},
		css: String,
	},
	computed: {
		getBrand() {
			switch (this.brand) {
				case 'mailchimp':
					return siMailchimp?.path;
				case 'googlesheets':
					return siGooglesheets?.path;
				case 'applemusic':
					return siApplemusic?.path;
				case 'audiomack':
					return siAudiomack?.path;
				case 'deezer':
					return siDeezer?.path;
				case 'iheartradio':
					return siIheartradio?.path;
				case 'tunein':
					return siTunein?.path;
				case 'spotify':
					return siSpotify?.path;
				case 'stitcher':
					return siStitcher?.path;
				case 'soundcloud':
					return siSoundcloud?.path;
				case 'youtube':
					return siYoutube?.path;
				case 'youtubemusic':
					return siYoutubemusic?.path;
				case 'genius':
					return siGenius?.path;
				case 'itunes':
					return siItunes?.path;
				case 'audible':
					return siAudible?.path;
				case 'google':
					return siGoogle?.path;
				case 'overcast':
					return siOvercast?.path;
				case 'breaker':
					return siBreaker?.path;
				case 'pocketcasts':
					return siPocketcasts?.path;
				case 'radiopublic':
					return siRadiopublic.path;
				case 'apple':
					return siApple?.path;
				case 'linkedin':
					return siLinkedin?.path;
				case 'twitter':
					return siTwitter?.path;
				case 'instagram':
					return siInstagram?.path;
				case 'facebook':
					return siFacebook?.path;
				case 'tiktok':
					return siTiktok?.path;
				case 'medium':
					return siMedium?.path;
				case 'whatsapp':
					return siWhatsapp?.path;
				case 'patreon':
					return siPatreon?.path;
				case 'pinterest':
					return siPinterest?.path;
				case 'goodreads':
					return siGoodreads?.path;
				case 'snapchat':
					return siSnapchat?.path;
				case 'tumblr':
					return siTumblr?.path;
				default:
					return null;
			}
		},
		iconSize() {
			if (this.size === 'xs') return 'text-sm';
			if (this.size === 'sm') return 'text-lg';
			if (this.size === 'md') return 'text-2xl';
			if (this.size === 'lg') return 'text-4xl';
			if (this.size === 'xl') return 'text-5xl';
			return 'text-lg';
		},
		brandSize() {
			if (this.size === 'xs') return 'w-3.5 h-3.5';
			if (this.size === 'sm') return 'w-5 h-5';
			if (this.size === 'md') return 'w-6 h-6';
			if (this.size === 'lg') return 'w-9 h-9';
			if (this.size === 'xl') return 'w-12 h-12';
			return 'text-lg';
		},
	},
};
