import { stripTags, removeLineBreaks } from '@/utils/helper';

export const state = () => ({
	show: false,
	autoDismiss: false,
	loading: false,
	message: null,
	theme: 'default',
});

export const actions = {
	showToast({ commit }, { show, autoDismiss, loading, message, theme }) {
		commit('show', show);
		commit('autoDismiss', autoDismiss);
		commit('loading', loading);

		if (message) commit('message', stripTags(removeLineBreaks(message)));
		if (theme) commit('theme', theme);
	},
	dismissToast({ commit }) {
		commit('show', false);
		commit('autoDismiss', false);
		commit('loading', false);
		commit('message', null);
		commit('theme', 'default');
	},
};

export const getters = {
	show: (state) => state.show,
	autoDismiss: (state) => state.autoDismiss,
	loading: (state) => state.loading,
	message: (state) => state.message,
	theme: (state) => state.theme,
};

export const mutations = {
	show(state, bool) {
		state.show = bool;
	},
	autoDismiss(state, bool) {
		state.autoDismiss = bool;
	},
	loading(state, bool) {
		state.loading = bool;
	},
	message(state, str) {
		state.message = str;
	},
	theme(state, str) {
		state.theme = str;
	},
};
