import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=270c21fc&scoped=true&"
import script from "./Button.vue?vue&type=script&lang=js&"
export * from "./Button.vue?vue&type=script&lang=js&"
import style0 from "./Button.vue?vue&type=style&index=0&id=270c21fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "270c21fc",
  null
  
)

export default component.exports