//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'NavButton',
	props: {
		id: String,
		href: String,
		path: String,
		callback: Function,
		label: String,
		info: String,
		icon: String,
		exact: Boolean,
	},
};
