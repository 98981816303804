//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
	name: 'Toast',
	props: {
		loading: Boolean,
		autoDismiss: Boolean,
		message: String,
		theme: {
			type: String,
			validator: (v) =>
				['success', 'warning', 'error', 'default'].includes(v),
			default: 'default',
		},
	},
	data() {
		return {
			timeout: null,
		};
	},
	mounted() {
		if (this.autoDismiss) {
			let timing = 3000;

			if (this.message) {
				timing = this.message.trim().length * 300;
			}

			this.timeout = setTimeout(() => {
				this.close();
			}, timing);
		}
	},
	beforeDestroy() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	},
	methods: {
		...mapActions({
			dismissToast: 'toast/dismissToast',
		}),
		close() {
			if (this.timeout) {
				clearTimeout(this.timeout);
			}

			this.dismissToast();
		},
		getTheme() {
			switch (this.theme) {
				case 'success':
					return {
						background: 'bg-green-500',
						color: 'text-green-100',
						buttonBackground: 'bg-green-700',
						buttonHover: 'bg-kadence-900',
						buttonTextColor: 'text-green-100',
					};
				case 'warning':
					return {
						background: 'bg-yellow-500',
						color: 'text-yellow-900',
						buttonBackground: 'bg-yellow-700',
						buttonHover: 'bg-yellow-900',
						buttonTextColor: 'text-yellow-100',
					};
				case 'error':
					return {
						background: 'bg-red-700',
						color: 'text-red-100',
						buttonBackground: 'bg-red-800',
						buttonHover: 'bg-red-900',
						buttonTextColor: 'text-red-100',
					};
				case 'default':
				default:
					return {
						background: 'bg-kadence-900',
						color: 'text-kadence-100',
						buttonBackground: 'bg-kadence-100',
						buttonHover: 'bg-kadence-400',
						buttonTextColor: 'text-kadence-900',
					};
			}
		},
	},
};
