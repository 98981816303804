import axios from 'axios';
import errorHandler from '../utils/errorHandler';

const apiKey = process.env.API_KEY;
const apiUrl = process.env.API_URL;
const baseUrl = process.env.BASE_URL;

export const http = {
	get: async (endpoint, params = {}, headers = {}) => {
		try {
			const isExternal = endpoint.indexOf('http') > -1 ? true : false;
			let url = !isExternal ? `/api${endpoint}` : endpoint;

			if (!isExternal && baseUrl) {
				url = `${baseUrl}/api${endpoint}`;
			}

			const { data } = await axios.get(
				url,
				{
					params: params,
				},
				{ headers: headers },
			);

			return data;
		} catch (e) {
			errorHandler(e);
		}
	},
	post: async (endpoint, body = {}, headers = {}) => {
		axios.defaults.headers.post['Content-Type'] =
			'application/x-www-form-urlencoded';

		try {
			const isExternal = endpoint.indexOf('http') > -1 ? true : false;
			let url = !isExternal ? `/api${endpoint}` : endpoint;

			if (!isExternal && baseUrl) {
				url = `${baseUrl}/api${endpoint}`;
			}

			const { data } = await axios.post(url, body, { headers: headers });

			return data;
		} catch (e) {
			errorHandler(e);
		}
	},
	put: async (endpoint, body = {}, headers = {}) => {
		axios.defaults.headers.post['Content-Type'] =
			'application/x-www-form-urlencoded';

		try {
			const isExternal = endpoint.indexOf('http') > -1 ? true : false;
			let url = !isExternal ? `/api${endpoint}` : endpoint;

			if (!isExternal && baseUrl) {
				url = `${baseUrl}/api${endpoint}`;
			}

			const { data } = await axios.put(url, body, { headers: headers });
			return data;
		} catch (e) {
			errorHandler(e);
		}
	},
	delete: async (endpoint, params = {}, headers = {}) => {
		try {
			const isExternal = endpoint.indexOf('http') > -1 ? true : false;
			let url = !isExternal ? `/api${endpoint}` : endpoint;

			if (!isExternal && baseUrl) {
				url = `${baseUrl}/api${endpoint}`;
			}

			const { data } = await axios.delete(
				url,
				{
					params: params,
				},
				{ headers: headers },
			);
			return data;
		} catch (e) {
			errorHandler(e);
		}
	},
	apiGet: async (endpoint, params = {}, bearerToken) => {
		if (bearerToken)
			axios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${bearerToken}`;

		axios.defaults.headers.common['x-api-key'] = apiKey;
		axios.defaults.headers.post['Content-Type'] =
			'application/x-www-form-urlencoded';

		try {
			const { data } = await axios.get(`${apiUrl}${endpoint}`, params);

			return data;
		} catch (e) {
			errorHandler(e);
		}
	},
	apiPost: async (endpoint, body = {}, bearerToken) => {
		if (bearerToken)
			axios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${bearerToken}`;

		axios.defaults.headers.common['x-api-key'] = apiKey;
		axios.defaults.headers.post['Content-Type'] =
			'application/x-www-form-urlencoded';

		try {
			const isExternal = endpoint.indexOf('http') > -1 ? true : false;
			let url = !isExternal ? `${apiUrl}${endpoint}` : endpoint;

			const { data } = await axios.post(url, body);

			return data;
		} catch (e) {
			errorHandler(e);
		}
	},
	apiPut: async (endpoint, body = {}, bearerToken) => {
		if (bearerToken)
			axios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${bearerToken}`;

		axios.defaults.headers.common['x-api-key'] = apiKey;
		axios.defaults.headers.post['Content-Type'] =
			'application/x-www-form-urlencoded';

		try {
			const { data } = await axios.put(`${apiUrl}${endpoint}`, body);

			return data;
		} catch (e) {
			errorHandler(e);
		}
	},
	apiDelete: async (endpoint, params, bearerToken) => {
		if (bearerToken)
			axios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${bearerToken}`;

		axios.defaults.headers.common['x-api-key'] = apiKey;

		try {
			const { data } = await axios.delete(`${apiUrl}${endpoint}`, {
				params: params,
			});

			return data;
		} catch (e) {
			errorHandler(e);
		}
	},
};

export default ({ $app }, inject) => {
	inject('http', http);
};
