//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
	name: 'InfoBar',
	computed: {
		...mapGetters({
			username: 'user/username',
			plan: 'user/plan',
		}),
	},
};
