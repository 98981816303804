import { render, staticRenderFns } from "./User.vue?vue&type=template&id=caa55bc8&"
import script from "./User.vue?vue&type=script&lang=js&"
export * from "./User.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/vercel/path0/src/components/Icon.vue').default,NavMenuButton: require('/vercel/path0/src/components/nav/MenuButton.vue').default,ChromeMenuUserProfileButton: require('/vercel/path0/src/components/chrome/menu/UserProfileButton.vue').default,ChromeMenuPanel: require('/vercel/path0/src/components/chrome/menu/Panel.vue').default})
