export default async ({ $http, store, route, redirect }) => {
	if (
		![
			'index',
			'register',
			'forgot-password',
			'auth-fail',
			'auth-logout',
			'auth-success',
		].includes(route.name)
	) {
		if (!store.state.user.authenticated) {
			return redirect(302, '/');
		}
	} else if (
		[
			'index',
			'register',
			'forgot-password',
			'auth-fail',
			'auth-logout',
			'auth-success',
		].includes(route.name)
	) {
		if (store.state.user.authenticated) {
			return redirect(302, `/${store.state.user.username}`);
		}
	}

	if (route?.params?.username) {
		if (route.params.username !== store.state.user.username) {
			const redirectTo = route.fullPath.replace(
				route.params.username,
				store.state.user.username,
			);
			return redirect(302, redirectTo);
		}
	}
};
