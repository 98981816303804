//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'Spinner',
	props: {
		text: String,
		textColor: {
			type: String,
			default: 'text-gray-800',
		},
		color: {
			type: String,
			default: 'text-kadence-400',
		},
	},
};
