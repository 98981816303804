//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'NavButton',
	props: {
		href: String,
		path: String,
		callback: Function,
		label: String,
		icon: String,
		exact: Boolean,
	},
	methods: {
		clickHandler() {
			if (this.callback) this.callback();
		},
	},
};
