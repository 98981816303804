export const state = () => ({
	authenticated: false,
	id: null,
	name: null,
	firstName: null,
	lastName: null,
	picture: null,
	username: null,
	email: null,
	plan: null,
	defaultProfile: null,
	profiles: null,
});

export const actions = {
	setUserId({ commit }, str) {
		commit('id', str);
	},
	authenticated({ commit }, bool) {
		commit('authenticated', bool);
	},
	setCurrentUser(
		{ commit },
		{
			name,
			firstName,
			lastName,
			picture,
			username,
			email,
			plan,
			defaultProfile,
			profiles,
		},
	) {
		if (name) commit('name', name);
		if (firstName) commit('firstName', firstName);
		if (lastName) commit('lastName', lastName);
		if (picture) commit('picture', picture);
		if (username) commit('username', username);
		if (email) commit('email', email);
		if (plan) commit('plan', plan);
		if (defaultProfile) commit('defaultProfile', defaultProfile);
		if (profiles) commit('profiles', profiles);
	},
	removeCurrentUser({ commit }) {
		commit('name', null);
		commit('firstName', null);
		commit('lastName', null);
		commit('picture', null);
		commit('username', null);
		commit('email', null);
		commit('plan', null);
		commit('defaultProfile', null);
		commit('profiles', null);
	},
};

export const getters = {
	id: (state) => state.id,
	authenticated: (state) => state.authenticated,
	name: (state) => state.name,
	firstName: (state) => state.firstName,
	lastName: (state) => state.lastName,
	picture: (state) => state.picture,
	username: (state) => state.username,
	email: (state) => state.email,
	plan: (state) => state.plan,
	defaultProfile: (state) => state.defaultProfile,
	profiles: (state) => state.profiles,
};

export const mutations = {
	id(state, str) {
		state.id = str;
	},
	authenticated(state, bool) {
		state.authenticated = bool;
	},
	name(state, str) {
		state.name = str;
	},
	firstName(state, str) {
		state.firstName = str;
	},
	lastName(state, str) {
		state.lastName = str;
	},
	picture(state, str) {
		state.picture = str;
	},
	username(state, str) {
		state.username = str;
	},
	email(state, str) {
		state.email = str;
	},
	plan(state, str) {
		state.plan = str;
	},
	defaultProfile(state, str) {
		state.defaultProfile = str;
	},
	profiles(state, arr) {
		state.profiles = arr;
	},
};
