//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
	name: 'HelpCenter',
	data() {
		return {
			ready: false,
		};
	},
	methods: {
		...mapActions({ showHelp: 'app/showHelp' }),
		loadHandler() {
			this.ready = true;
		},
		close() {
			this.showHelp(false);
		},
	},
};
