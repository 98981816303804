//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { isMobile, isTablet } from '@/utils/helper';

export default {
	name: 'AuthenticatedSidebar',
	watch: {
		$route() {
			if (isMobile() || isTablet()) {
				this.menuOpen = false;
			} else {
				this.menuOpen = true;
			}
		},
	},
	data() {
		return {
			menuOpen: false,
		};
	},
	computed: {
		...mapGetters({
			profile: 'profile/about',
			showHelp: 'app/showHelp',
		}),
		profileProgress() {
			if (this.profile?.profileCompletion) {
				return `${this.profile.profileCompletion * 100}%`;
			}

			return null;
		},
	},
	methods: {
		...mapActions({
			showHelpCenter: 'app/showHelp',
		}),
		toggleMenu() {
			this.menuOpen = !this.menuOpen;
		},
		toggleHelpCenter() {
			if (!isMobile() && !isTablet()) {
				this.showHelpCenter(!this.showHelp);
			} else {
				this.showHelpCenter(true);
				this.menuOpen = false;
			}
		},
		resizeHandler() {
			if (!isMobile() && !isTablet()) this.menuOpen = true;
		},
	},
	mounted() {
		window.addEventListener('resize', this.resizeHandler);
		this.resizeHandler();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeHandler);
	},
};
