import { browserCache } from '@/utils/helper';

export const state = () => ({
	env: 'development',
	busy: true,
	ready: false,
	connectionSpeed: null,
	mobile: null,
	tablet: null,
	desktop: null,
	menuCollapsed: false,
	currentPageTitle: 'Dashboard',
	menuBlocker: false,
	userMenuOpen: false,
	deviceToken: null,
	showHelp: false,
});

export const actions = {
	env({ commit }, str) {
		commit('env', str);
	},
	busy({ commit }, bool) {
		commit('busy', bool);
	},
	ready({ commit }, bool) {
		commit('ready', bool);
	},
	connectionSpeed({ commit }, str) {
		commit('connectionSpeed', str);
	},
	mobile({ commit }, bool) {
		commit('mobile', bool);
	},
	tablet({ commit }, bool) {
		commit('tablet', bool);
	},
	desktop({ commit }, bool) {
		commit('desktop', bool);
	},
	menuCollapsed({ commit }, bool) {
		commit('menuCollapsed', bool);
	},
	currentPageTitle({ commit }, str) {
		commit('currentPageTitle', str);
	},
	openUserMenu({ commit }, bool) {
		commit('userMenuOpen', bool);
		commit('menuBlocker', bool);
	},
	closeAllMenus({ commit }) {
		commit('userMenuOpen', false);
		commit('menuBlocker', false);
	},
	deviceToken({ commit }, str) {
		commit('deviceToken', str);
	},
	showHelp({ commit }, bool) {
		commit('showHelp', bool);
	},
};

export const getters = {
	env: (state) => state.env,
	busy: (state) => state.busy,
	ready: (state) => state.ready,
	connectionSpeed: (state) => state.connectionSpeed,
	mobile: (state) => state.mobile,
	tablet: (state) => state.tablet,
	desktop: (state) => state.desktop,
	menuCollapsed: (state) => state.menuCollapsed,
	currentPageTitle: (state) => state.currentPageTitle,
	userMenuOpen: (state) => state.userMenuOpen,
	menuBlocker: (state) => state.menuBlocker,
	deviceToken: (state) => state.deviceToken,
	showHelp: (state) => state.showHelp,
};

export const mutations = {
	env(state, str) {
		state.env = str;
	},
	busy(state, bool) {
		state.busy = bool;
	},
	ready(state, bool) {
		state.ready = bool;
	},
	connectionSpeed(state, str) {
		state.connectionSpeed = str;
	},
	mobile(state, bool) {
		state.mobile = bool;
	},
	tablet(state, bool) {
		state.tablet = bool;
	},
	desktop(state, bool) {
		state.desktop = bool;
	},
	menuCollapsed(state, bool) {
		browserCache.set('menuCollapsed', bool);
		state.menuCollapsed = bool;
	},
	currentPageTitle(state, str) {
		state.currentPageTitle = str;
	},
	menuBlocker(state, bool) {
		state.menuBlocker = bool;
	},
	userMenuOpen(state, bool) {
		state.userMenuOpen = bool;
		state.menuBlocker = bool;
	},
	deviceToken(state, str) {
		state.deviceToken = str;
	},
	showHelp(state, bool) {
		state.showHelp = bool;
	},
};
