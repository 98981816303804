import Cookies from 'cookies';

export const actions = {
	async nuxtServerInit({}, { req, res, redirect, $http, store, route }) {
		try {
			const cookies = new Cookies(req, res);
			const accessToken = req?.headers.authorization
				? req?.headers.authorization.split(' ')[1]
				: cookies.get(process.env.AUTH_COOKIE_NAME);

			if (accessToken && route.name !== 'auth-logout') {
				const { authenticated, authResponse } = await $http.post(
					'/auth/status',
					null,
					{
						Authorization: `Bearer ${accessToken}`,
					},
				);

				store.commit('user/id', authResponse.id);
				store.commit('user/authenticated', authenticated);
				store.commit('user/name', authResponse.name);
				store.commit('user/firstName', authResponse.firstName);
				store.commit('user/lastName', authResponse.lastName);
				store.commit('user/email', authResponse.email);
				store.commit('user/picture', authResponse.avatar);
				store.commit('user/username', authResponse.handle);
				store.commit('user/plan', authResponse.plan);
				store.commit(
					'user/defaultProfile',
					authResponse.defaultProfile,
				);
				store.commit('user/profiles', authResponse.profiles);
				store.commit('profile/about', authResponse.about);
				store.commit('app/deviceToken', authResponse.tokenId);

				if (
					[
						'index',
						'register',
						'forgot-password',
						'auth-fail',
						'auth-logout',
						'auth-success',
					].includes(route.name)
				) {
					redirect(302, `/${authResponse.handle}`);
				}

				if (route?.params?.username) {
					if (route.params.username !== authResponse.handle) {
						const redirectTo = route.fullPath.replace(
							route.params.username,
							authResponse.handle,
						);
						redirect(302, redirectTo);
					}
				}
			} else {
				if (
					![
						'index',
						'register',
						'forgot-password',
						'auth-fail',
						'auth-logout',
						'auth-success',
					].includes(route.name)
				) {
					// redirect(302, '/');
				}
			}
		} catch (err) {
			console.error('error filling store');
			console.error(err);
			redirect(302, '/');
		}
	},
};
