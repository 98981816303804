//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
	name: 'AuthenticatedHeader',
	computed: {
		...mapGetters({
			pageTitle: 'app/currentPageTitle',
		}),
	},
};
