//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'UserProfileButton',
	props: {
		profileId: {
			type: String,
			required: true,
		},
		avatar: String,
		handle: {
			type: String,
			required: true,
		},
		isDefault: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			userProfiles: 'user/profiles',
		}),
	},
	methods: {
		...mapActions({
			appBusy: 'app/busy',
		}),
		async setAsDefaultProfile() {
			try {
				this.appBusy(true);
				const profile = this.userProfiles.find(
					(p) => p.id === this.profileId,
				);

				if (!profile.default) {
					await this.$http.put('/identities', {
						defaultProfile: this.profileId,
					});

					window.location.href = this.$config.baseURL;
				} else {
					this.appBusy(false);
				}

				this.$emit('selected');
			} catch (err) {
				this.appBusy(false);
				console.error(err);
			}
		},
	},
};
