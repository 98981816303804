//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'ChromeUserMenu',
	watch: {
		$route() {
			window.removeEventListener('click', this.closeListener);
			this.showProfiles = false;
			this.appBusy(false);
		},
	},
	data() {
		return {
			showProfiles: false,
		};
	},
	computed: {
		...mapGetters({
			userMenuOpen: 'app/userMenuOpen',
			showHelp: 'app/showHelp',
			firstName: 'user/firstName',
			lastName: 'user/lastName',
			username: 'user/username',
			userPicture: 'user/picture',
			userProfiles: 'user/profiles',
			profile: 'profile/about',
		}),
		profileProgress() {
			if (this.profile?.profileCompletion) {
				return `${this.profile.profileCompletion * 100}%`;
			}

			return null;
		},
	},
	beforeDestroy() {
		this.closeMenu();
	},
	methods: {
		...mapActions({
			appBusy: 'app/busy',
			openUserMenu: 'app/openUserMenu',
			closeAllMenus: 'app/closeAllMenus',
			toggleHelp: 'app/showHelp',
		}),
		toggleHelpCenter() {
			this.toggleHelp(!this.showHelp);
			this.closeMenu();
			this.closeAllMenus();
		},
		toggleUserMenu() {
			window.removeEventListener('click', this.closeListener);
			window.addEventListener('click', this.closeListener);
			this.openUserMenu(!this.userMenuOpen);
		},
		closeListener(e) {
			if (this.clickOutsideHandler(e)) {
				this.closeMenu();
			}
		},
		closeMenu() {
			window.removeEventListener('click', this.closeListener);
			this.openUserMenu(false);
			this.showProfiles = false;
		},
		clickOutsideHandler(evt) {
			if (!this.$el.contains(evt.target)) return true;
		},
		switchProfile() {
			this.showProfiles = !this.showProfiles;
		},
	},
};
